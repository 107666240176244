
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import SignupPassword from '@/components/shared/SignupPassword.vue'

export default defineComponent({
  components: {
    SignupPassword,
    TmButton,
    TmModal,
    TmFormLine,
  },
  setup() {
    const username = ref('textmagic123')
    const oldPassword = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')

    return {
      username,
      oldPassword,
      newPassword,
      confirmPassword,
    }
  },
})
